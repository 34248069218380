<template>
  <div class="card">
    <div class="card-body">
      <form v-on:submit.prevent="register">
        <div class="mb-2 row">
          <label for="name" class="col-md-4 col-form-label text-md-right">Name</label>
          <div class="col-md-6">
            <input id="name" type="text" class="form-control" v-bind:class="{ 'is-invalid': errors.name }" name="name" v-model="registration.name" required autocomplete="name" />
            <div v-if="errors.name" class="alert alert-danger">
              <div v-for="error in errors.name" :key="error">{{ error }}</div>
            </div>
          </div>
        </div>
        <div class="mb-2 row">
          <label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>
          <div class="col-md-6">
            <input id="email" type="email" class="form-control" v-bind:class="{ 'is-invalid': errors.email }" name="email" v-model="registration.email" required autocomplete="email" />
            <div v-if="errors.email" class="alert alert-danger">
              <div v-for="error in errors.email" :key="error">{{ error }}</div>
            </div>
          </div>
        </div>
        <div class="mb-4 row">
          <label for="phone" class="col-md-4 col-form-label text-md-right">Phone</label>
          <div class="col-md-6">
            <input id="phone" type="text" class="form-control" v-bind:class="{ 'is-invalid': errors.phone }" name="phone" v-model="registration.phone" required autocomplete="phone" />
            <div v-if="errors.phone" class="alert alert-danger">
              <div v-for="error in errors.phone" :key="error">{{ error }}</div>
            </div>
          </div>
        </div>
        <div class="mb-2 row">
          <label for="name" class="col-md-4 col-form-label text-md-right">Password</label>
          <div class="col-md-6">
            <input id="password" type="password" class="form-control" v-bind:class="{ 'is-invalid': errors.password }" name="password" v-model="registration.password" required />
          </div>
        </div>
        <div class="mb-2 row">
          <label for="email" class="col-md-4 col-form-label text-md-right">Repeat password</label>

          <div class="col-md-6">
            <input id="password_confirmation" type="password" class="form-control" v-bind:class="{ 'is-invalid': errors.password }" name="password_confirmation" v-model="registration.password_confirmation" required />
          </div>
        </div>
        <div v-if="errors.password" class="alert alert-danger mb-2">
          <div v-for="error in errors.password" :key="error">{{ error }}</div>
        </div>
        <div class="text-right mb-2">
          <button class="btn btn-success">Register</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      registration: {
        name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
      },
      errors: {},
    };
  },
  methods: {
    async register() {
      try {
        this.errors = {};
        const { data } = await this.$http.post("/register", this.registration);
        this.$cookies.set("fp-token", data.token);
        this.$http.defaults.headers["Authorization"] = "Bearer " + data.token;
        localStorage.setItem("member", JSON.stringify(data.member));
        localStorage.setItem("messages", JSON.stringify(data.messages));
        if (this.$cookies.get("fp-token") != null) {
          if (this.$route.params.nextUrl != null) {
            this.$router.push(this.$route.params.nextUrl);
          } else {
            this.$router.push("/");
          }
        }
      } catch (error) {
        this.errors = error.response.data.errors;
      }
    },
  },
};
</script>